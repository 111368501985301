var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "ad-guard-stats-wrapper" }, [
    _vm.queryCount && _vm.blockCount
      ? _c("div", { staticClass: "summary" }, [
          _c("div", [
            _c("span", { staticClass: "lbl" }, [_vm._v("Queries:")]),
            _c("span", { staticClass: "val" }, [
              _vm._v(_vm._s(_vm.queryCount)),
            ]),
          ]),
          _c("div", [
            _c("span", { staticClass: "lbl" }, [_vm._v("Blocked:")]),
            _c("span", { staticClass: "val" }, [
              _vm._v(_vm._s(_vm.blockCount)),
            ]),
          ]),
        ])
      : _vm._e(),
    _c("p", { staticClass: "block-pie", attrs: { id: _vm.chartId } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }